import Vue from "vue";
import VueI18n from "vue-i18n";
import customZhCn from "~/lang/zh-CN";
import customEnUs from "~/lang/en";
import customKm from "~/lang/km";

export default ({ app, store }) => {
    Vue.use(VueI18n);
    // Set i18n instance on app
    // This way we can use it in middleware and pages asyncData/fetch
    const messages = {
        "zh-cn": Object.assign({}, customZhCn),
        en: Object.assign({}, customEnUs),
        km: Object.assign({}, customKm),
    };
    let LANG = "en";
    if (process.client) {
        LANG = localStorage.getItem("language") || "zh-cn";
    }

    const i18n = new VueI18n({
        // locale: store.state.i18n.locale,
        locale: LANG,
        fallbackLocale: "zh-cn",
        messages,
    });

    app.i18n = i18n;
}