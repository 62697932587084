export default {
  "WhiteWolfKill" : "血染狼村",
  "BrandAndService" : "品牌与服务",
  "PlayerZone" : "玩家专区",
  "AboutSects" : "关于门派",
  "ContactUs" : "关于我们",
  "Download" : "下载",
  "FaceKillingEnd" : "面杀端",
  "HandSwimmingEnd" : "手游端",
  "DownloadGame" : "下载游戏",
  "BrandAdvantage" : "品牌优势",
  "BrandAdvantageIntroduce" : "一流的游戏交互，震撼的视觉和听觉体验，超前的游戏玩法设计，竞技赛事级别的算法",
  "RoleIntroduction" : "角色介绍",
  "OfflineExperienceStore" : "线下体验店",
  "SearchStore" : "搜索店面",
  "IntroductionToGoodPeopleCamp" : "平静的小村庄每晚都会受到狼人的侵袭。每到入夜，狼人都会在村中进行抢劫，并且会有一个村民成为这群狼人的牺牲品，然而村民们不会坐以待毙……",
  "IntroductionToWerewolfCamp" : "本身没有特殊技能。平民会接收到真假混杂的信息，需要从中分辨和判断出正确的信息，认真的投出每一票，直到放逐所有狼人。",
  "IntroductionToTheThirdPartyCamp" : "第三方阵营顾名思义即不属于狼人一方也不属于好人阵营，他们属于独立的第三方，当然他们也有自己的使命除掉场上的狼人或好人才能取得胜利。",
  "BrandCulture" : "品牌文化",
  "BrandCultureIntroduce" : "血染狼村是一款超专业的狼人杀面杀工具软件，大家可以在这里尽情的挑战你的智商，白狼王、恶魔、狼美人和预言家等很多身份就是支持的，新手用户也可以用App观战。品牌自研发了Pad游戏端、App手游和TV端观战，非常适合桌游馆和专业赛事组织比赛！自推出以来，已在海内外多个国家吸引到如Mr.x等知名桌游馆的加盟运营，新颖的游戏交互和完善的配套软硬件，也吸引了越来越多的新老玩家进驻体验。",
  "OurAdvantages" : "我们的优势",
  "OurAdvantagesIntroduce" : "一流的游戏交互，震撼的视觉和听觉体验，超前的游戏玩法设计，竞技赛事级别的算法听觉体验",
  "BrandService" : "品牌服务",
  "BrandServiceIntroduce" : "一流的游戏交互，震撼的视觉和听觉体验，超前的游戏玩法设计，竞技赛事级别的算法",
  "PageFooterintroduce" : "专业的狼人杀面杀游戏平台",
  "PrivacyPolicy" : "隐私政策",
  "UserAgreement" : "用户协议",
  "AboutUs" : "联系我们",
  "InvestmentPromotion" : "招商加盟",
  "ContactUsIceIntroduce" : "江苏墨穹文化发展有限公司于2019年9月20日正式成立，坐落在美丽的南京市江苏，是一家国内为数不多的，具备软件研发、定制实力，并将研发成果广泛应用于生产、工作的科技研发型企业。自成立以来，墨穹文化致力于为用户提供安全、方便、快捷的互联网信息服务，以其专业的眼光及创新水平不断推陈出新，在移动端方面的开发紧跟市场趋势，为满足广大用户的需求，致力于Android和iOS平台的移动应用开发，为智能手机用户提供优质的软件服务和有趣的游戏体验。",
  "EnterYourName" : "输入您的姓名",
  "EnterYourPhoneNumber" : "输入您的电话号码",
  "ApplyForJoining" : "申请加盟",
  "invitetoonesidemenofwisdomandvalor" : "招贤纳士",
  "invitetoonesidemenofwisdomandvalorIntroduce" : `我们殷切地期盼很多、很多的新同事一起来共同打造这个世界，我们对现阶段的体验品质并不满意，对当前的内容生产效率也颇有忧虑。我们提供大量的核心岗位，出色的薪酬福利，以及让人感动的工作室环境，并且有中国与金边两地可供选择。
  我们希望你是一个职业素养，专业能力与从业热情都足够高的人。我们不在意所谓的学历与履历，不过有自己的作品，会有加分。`,
  "SectInformation" : "门派信息",
  "ApplyToJoin" : "申请加入",
  "SectMembers" : "门派成员",
  "site" : "场",
  "TotalWinningRate" : "总胜率",
  "SectPlayer" : "门派选手",
  "CharmValue" : "魅力值",
  "VictoryAndDefeatField" : "胜负场",
  "integral" : "积分",
  "MostWinningRoles" : "获胜最多角色",
  "PlayerLeaderboard" : "玩家排行榜",
  "Activity" : "活跃度",
  "ranking" : "排名",
  "GamePlayer" : "玩家",
  "GameIntroduction" : "游戏攻略",
  "Novice" : "新手",
  "tactics" : "战术",
  "Detailedplay" : "玩法详解",
  "RoleSkills" : "角色技能",
  "XiaobianComments" : "小编点评",
}
