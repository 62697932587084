export default {
  "WhiteWolfKill" : "WhiteWolfKill",
  "BrandAndService" : "BrandAndService",
  "PlayerZone" : "PlayerZone",
  "AboutSects" : "AboutSects",
  "ContactUs" : "ContactUs",
  "Download" : "Download",
  "FaceKillingEnd" : "FaceKillingEnd",
  "HandSwimmingEnd" : "HandSwimmingEnd",
  "DownloadGame" : "DownloadGame",
  "BrandAdvantage" : "BrandAdvantage",
  "BrandAdvantageIntroduce" : "First class game interaction, shocking visual and auditory experience, advanced game playing design, competitive event level algorithm",
  "RoleIntroduction" : "RoleIntroduction",
  "OfflineExperienceStore" : "OfflineExperienceStore",
  "SearchStore" : "SearchStore",
  "IntroductionToGoodPeopleCamp" : "The peaceful village is attacked by werewolves every night. Every night, werewolves rob the village, and a villager will become the victim of these werewolves. However, the villagers will not wait to die",
  "IntroductionToWerewolfCamp" : "They have no special skills. Civilians will receive mixed true and false information. They need to distinguish and judge the correct information, and seriously vote for each vote until they exile all werewolves.",
  "IntroductionToTheThirdPartyCamp" : "As the name suggests, the third-party camp does not belong to the werewolf side or the good man camp. They belong to an independent third party. Of course, they also have their own mission. Only by getting rid of werewolves or good people on the field can they win.",
  "BrandCulture" : "BrandCulture",
  "BrandCultureIntroduce" : "White Wolf kill is a super professional werewolf face killing tool software. You can challenge your IQ here. Many identities such as white wolf king, devil, wolf beauty and prophet are supported. Novice users can also watch the war with app. The brand has developed pad game terminal, APP mobile game and TV terminal to watch the game, which is very suitable for table game halls and professional events! Since its launch, it has attracted such as Mr in many countries at home and abroad",
  "OurAdvantages" : "OurAdvantages",
  "OurAdvantagesIntroduce" : "First class game interaction, shocking visual and auditory experience, advanced game playing design, and competitive event level algorithm auditory experience",
  "BrandService" : "BrandService",
  "PageFooterintroduce" : "Professional werewolf face killing game platform",
  "PrivacyPolicy" : "PrivacyPolicy",
  "UserAgreement" : "UserAgreement",
  "AboutUs" : "AboutUs",
  "InvestmentPromotion" : "InvestmentPromotion",
  "ContactUsIceIntroduce" : "Jiangsu moqiong Culture Development Co., Ltd. was officially established on September 20, 2019. It is located in the beautiful Nanjing Jiangsu. It is one of the few domestic science and technology R &amp; D enterprises with software R &amp; D and customization strength, and widely applies the R &amp; D achievements to production and work. Since its establishment, moqiong culture has been committed to providing users with safe, convenient and fast Internet information services. With its professional vision and innovation level, it has constantly pushed through the old and brought forth the new. Its development in mobile terminal closely follows the market trend. In order to meet the needs of users, it is committed to the development of mobile applications on Android and IOS platforms, Provide high-quality software services and interesting game experience for smartphone users.",
  "EnterYourName" : "EnterYourName",
  "EnterYourPhoneNumber" : "EnterYourPhoneNumber",
  "ApplyForJoining" : "ApplyForJoining",
  "invitetoonesidemenofwisdomandvalor" : "hire",
  "invitetoonesidemenofwisdomandvalorIntroduce" : `We earnestly hope that many new colleagues will work together to build this world. We are not satisfied with the quality of experience at this stage and are worried about the current content production efficiency. We provide a large number of core positions, excellent salary and benefits, as well as a moving studio environment, and there are options in China and Phnom Penh.
  We hope you are a person with high professional quality, professional ability and enthusiasm. We don't care about the so-called education and resume, but if we have our own works, we will get extra points.`,
  "SectInformation" : "SectInformation",
  "ApplyToJoin" : "ApplyToJoin",
  "SectMembers" : "Sect members",
  "site" : "site",
  "TotalWinningRate" : "TotalWinningRate",
  "SectPlayer" : "SectPlayer",
  "CharmValue" : "CharmValue",
  "VictoryAndDefeatField" : "VictoryAndDefeatField",
  "integral" : "integral",
  "MostWinningRoles" :  "MostWinningRoles",
  "PlayerLeaderboard" : "PlayerLeaderboard",
  "Activity" : "Activity",
  "ranking" : "ranking",
  "GamePlayer" : "GamePlayer",
  "GameIntroduction" : "GameIntroduction",
  "Novice" : "Novice",
  "tactics" : "tactics",
  "Detailedplay" : "Detailedplay",
  "RoleSkills" : "RoleSkills",
  "XiaobianComments" : "XiaobianComments",
}
