/**
 * 暂时只做chrome浏览器的音频自动播放
 * 简单的支持循环播放背景音乐和支持多个音频同时播放
 * @param {String} src 音频的地址
 * @param {String} class 音频自定义class，默认的cls-voice会在某些rounds会被销毁
 * @param {Number} timeout 单位：秒/s 音频播放后是否自动销毁iframe的dom；设置一个时间倒数后删掉dom
 * @param {Boolean} loop 音频重复播放，实际上是不断的移除和创建dom，根据timeout的时间循环
 * @param {Boolean} destroy 特殊情况下需要全部销毁现有的音频，譬如强制下一轮/狼人自爆/等等
 * @return {Promise} play()
 * by Dawsonliu 2019/05/15
 */

class Voice {
	constructor(params){
		this.params = params;
		this.Timer = null;

		this.play();
		// this.renderDom();
	}
	play() {
		const params = this.params;
		return new Promise((resolve, reject) => {
			try {
				const _time = Date.now();
				params.id = `voice-${_time}`;
				this.injectVoice(params);

				// 一种情况是只是时间到了就移除dom
				if (params.timeout && !params.loop) {
					let timeout = params.timeout;
					setTimeout(() => {
						document.getElementById(params.id) && document.getElementById(params.id).remove();
					}, timeout * 1000);
				}
			
				// 另外一个情况是需要不断的根据timeout去loop播放
				if (params.loop) {
					let timeout = params.timeout;
					clearTimeout(this.Timer);
					this.Timer = setTimeout(() => {
						document.getElementById(params.id) && document.getElementById(params.id).remove();
						this.injectVoice(params)
					}, timeout * 1000);
				}
			} catch (e) {
				console.error(e)
				reject(e)
			}
		})
	}
	injectVoice(params){
		if (!params.src) return console.error(`音频地址不能为空`);
	
		// 需要全部销毁音频，而且清除对应的定时器
		if (params.destroy) {
			document.querySelectorAll('.cls-voice').forEach(item => {
				item.remove();
			});
			clearTimeout(this.Timer)
		}

		let iframe = document.createElement('iframe');
		iframe.src = params.src;
		iframe.allow = 'autoplay';
		iframe.style = 'opacity: 0;position: absolute;z-index:-1;left: -10rem;top: -10rem;';
		iframe.id = params.id;
		iframe.setAttribute('class', `${params.class ? params.class : 'cls-voice'}`)
		params.timeout && iframe.setAttribute('data-destroy', params.timeout);
		params.loop && iframe.setAttribute('loop', true);
		document.body.appendChild(iframe);
	}
	renderDom(){
		const {params} = this;
		return new Promise((resolve, reject)=>{
			if (!params.src){
				console.error(`音频地址不能为空`);
				resolve(false);
			}

			const _time = Date.now();
			let audio = document.createElement('audio');
			audio.src = params.src;
			audio.autoplay = "autoplay";
			audio.id = `voice-${_time}`;
			audio.muted = true;
			audio.style = 'opacity: 0;position: absolute;z-index:-1;left: -10rem;top: -10rem;';
			document.body.appendChild(audio);

			audio.play().then(()=>{
				console.log('Audio play normal');
				resolve(true);
			}).catch(()=>{
				console.log('Audio play failed');
				// audio.currentTime = 0;  // 设置播放的音频的起始时间
				// audio.volume = 0.5;     // 设置音频的声音大小
				// audio.muted = false;    // 关闭静音状态
				// audio hack，用iframe播放
				resolve(false);
			})
		})
	}
}

export default function({app}, inject){
	inject('voice', Voice);
}