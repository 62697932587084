const LOCALES = ["zh-cn", "en", "km"];

const GetQueryString = (name) => {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  var r = window.location.search.substr(1).match(reg); //获取url中"?"符后的字符串并正则匹配
  var context = "";
  if (r != null) context = r[2];
  reg = null;
  r = null;
  return context == null || context == "" || context == "undefined"
      ? ""
      : context;
};
let locale = "zh-cn";

/* //注释掉暂时中文
if (process.browser) {
  // 从本地读LS语言设置，优先从url读多语言参数
  let lan = "zh-cn"; // 默认中文语言
  if (localStorage.getItem("language")) {
    locale = localStorage.getItem("language");
  }
  if (GetQueryString("lan")) {
    lan = GetQueryString("lan").toLocaleLowerCase();
    if (lan === "zh-cn" || lan === "zh" || lan === "cn") {
      lan = "zh-cn";
    }
    locale = lan;
    localStorage.setItem("language", lan);
  }
}
*/

export default {
  namespaced: true,
  state: () => ({
    locales: LOCALES,
    locale,
  }),
  mutations: {
    set_lang(state, locale) {
      if (state.locales.indexOf(locale) !== -1) {
          state.locale = locale;
          localStorage.setItem("language", locale);
          this.app.i18n.locale = locale;
      }
    },
  }
}