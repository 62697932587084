export const state = ()=>{
  return {
    h5_menuShow: false,
    downloadpageShow: false,
  }
}

export const mutations = {
  h5_menuShow: (state, statusvalue) => {
    state.h5_menuShow = statusvalue;
  },
  downloadpageShow: (state, statusvalue) => {
    state.downloadpageShow = statusvalue;
  }
}
