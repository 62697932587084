export default app => {

    const roleList = (method = 'get', params = {}) => {
        if (method === 'get') {
            params = { params };
        }
        return app.$axios[method]('/mock/lrs/role_list.json', params);
    };

    const memberList = (method = 'get', params = {}) => {
        if (method === 'get') {
            params = { params };
        }
        return app.$axios[method]('/mock/lrs/trend_list.json', params);
    };

    return {
        roleList,
        memberList
    }
}

