/**
 * google analitics统计插件
 * 统一配置在config的prod环境
 */
import Config from '~/config'

export default ({ app, store }) => {
    app.router.afterEach((to, from) => {
        if (process.browser && Config.client.ga && process.env.NODE_ENV === 'production') {
            let pageTitle = document.querySelector('title').innerHTML;
            app.$gtag('config', Config.client.ga, {
                page_title: pageTitle,
                page_path: `${Config.client.domain}${to.fullPath}`,
                send_page_view: false
            });
        }
    })
}