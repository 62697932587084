import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _cd6a37e4 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _6472bb76 = () => interopDefault(import('../pages/error.vue' /* webpackChunkName: "pages/error" */))
const _585b7c0a = () => interopDefault(import('../pages/faction.vue' /* webpackChunkName: "pages/faction" */))
const _23355394 = () => interopDefault(import('../pages/play.vue' /* webpackChunkName: "pages/play" */))
const _23ea3423 = () => interopDefault(import('../pages/service.vue' /* webpackChunkName: "pages/service" */))
const _58cb23ff = () => interopDefault(import('../pages/characterIntroduction_details/_id.vue' /* webpackChunkName: "pages/characterIntroduction_details/_id" */))
const _07247d38 = () => interopDefault(import('../pages/text_teaching/_id.vue' /* webpackChunkName: "pages/text_teaching/_id" */))
const _20ac9d40 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/contact",
    component: _cd6a37e4,
    name: "contact"
  }, {
    path: "/error",
    component: _6472bb76,
    name: "error"
  }, {
    path: "/faction",
    component: _585b7c0a,
    name: "faction"
  }, {
    path: "/play",
    component: _23355394,
    name: "play"
  }, {
    path: "/service",
    component: _23ea3423,
    name: "service"
  }, {
    path: "/characterIntroduction_details/:id?",
    component: _58cb23ff,
    name: "characterIntroduction_details-id"
  }, {
    path: "/text_teaching/:id?",
    component: _07247d38,
    name: "text_teaching-id"
  }, {
    path: "/",
    component: _20ac9d40,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
