export default function(context){
  var browser = {
    versions: function() {
        var u = navigator.userAgent;
        return {
            // 判断是否IE内核
            trident: u.indexOf('Trident') > -1,
            // 判断是否opera内核
            presto: u.indexOf('Presto') > -1,
            // 判断是webKit苹果谷歌内核
            webKit: u.indexOf('AppleWebKit') > -1,
            // 判断是否火狐内核
            gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1,

            // 判断浏览器类型
            firefox: u.indexOf('Firefox') > -1,
            chrome: u.indexOf('Chrome') > -1,
            safari: !u.indexOf('Chrome') > -1 && u.indexOf('Safari') > -1,
            opera: u.indexOf('Opera') > -1,
            ie: u.indexOf('MSIE') > -1,

            // 判断是否为移动终端
            mobile: !!u.match(/AppleWebKit.*Mobile.*/),
            // 判断是否为Windows平台
            windows: u.indexOf('Windows'),

            // 判断是否ios终端
            ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/),
            // 判断是否android终端
            android: u.indexOf('Android') > -1 || u.indexOf('Adr') > -1,
            // 判断是否为iPhone终端
            iPhone: u.indexOf('iPhone') > -1 ,
            // 判断是否iPad
            iPad: u.indexOf('iPad') > -1,
            // 判断是否iPod
            iPod: u.indexOf('iPod') > -1,
            //是否web应用程序
            webApp: u.indexOf('Safari') == -1,

            // 判断是否微信浏览器
            weixin: u.indexOf('MicroMessenger') > -1,
            // 判断是否为QQ浏览器
            qq: u.match(/\sQQ/i) == " qq" //是否QQ
        }
    }()
  };

  //判断pc跳m 或者m跳pc
  /*
  www1.langrensha.game 内网
  testwww.langrensha.game 外网
  www.langrensha.game 外网正式

  m1.langrensha.game 内网
  testm.langrensha.game / testlrsfem.aostatic.com 外网
  m.langrensha.game / lrsfem.aostatic.com 外网正式

  http:// 内网
  https:// 外网
  https:// 正式
  */ 


  var href = window.location.href;

  if(browser.versions.mobile){//移动
      //如果当前是pc地址才执行
      if( /www1\./.test(href) ){ //内网
        window.location.href = 'http://m1.langrensha.game'
      }else if( /testwww\./.test(href) ){ //外网
        window.location.href = 'https://testm.langrensha.game'
      }else if( /www\./.test(href) ){
        window.location.href = 'https://m.langrensha.game'
      }
  }else{//pc 
      //如果当前是移动地址才执行
      if( /m1\./.test(href) ){ //内网
        window.location.href = 'http://www1.langrensha.game'
      }else if( /testm\./.test(href) || /testlrsfem\./.test(href) ){ //外网
        window.location.href = 'https://testwww.langrensha.game'
      }else if( /m\./.test(href) || /lrsfem\./.test(href) ){
        window.location.href = 'https://www.langrensha.game'
      }
  }
}