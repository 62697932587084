<template>
  <nuxt />
</template>
<script>
export default {

}
</script>

<style lang="less">

html {
    font-size: 100px;
}
body {
    font-size: 0.14rem;
    color: #666;
}
html,
body,
#__nuxt,
#__layout{
    height: 100%;
}
html,
body {
    // background-image: radial-gradient(60% 88%, #62566D 37%, #332B3B 88%);
    background-color: #fff;
}
</style>
